// Once we can abandon pre-webkit Edge, all this extra junk can be removed
// Just declare the component classes and register them
if (!window.customElements) {
    const polyfillScript = document.createElement('script');
    polyfillScript.src = '/js/webComponentPolyfill-1.6.0.min.js';
    document.querySelector('head').appendChild(polyfillScript);
    polyfillScript.onload = () => {
        initComponents();
    }
} else {
    initComponents();
}

function initComponents() {
    class Header extends HTMLElement {
        connectedCallback() {
            this.innerHTML = `
                <header>
                    <div class="headerLogo">
                        <a href="/default.htm#home">
                            <img src="/assets/images/UI/logoCards.png" alt="Cards Logo" />
                            <div role="heading" aria-level="1">Microsoft Casual Games</div>
                        </a>
                    </div>

                    <a class="skipToContentLink" onClick="scrollToElement('main')" onkeyup="if (event.code === 'Enter' || event.code === 'Space'){scrollToElement('main')}" role="link" tabindex="0">
                        <div>Skip to main content</div>
                    </a>

                    <a class="skipToContentLink" onClick="scrollToElement('footer')" onkeyup="if (event.code === 'Enter' || event.code === 'Space'){scrollToElement('footer')}" role="link" tabindex="0">
                        <div>Skip to footer content</div>
                    </a>

                    <div class="headerMenu">
                        <ul>
                            <li class="menuLink">
                                <a href="/default.htm#home">home</a>
                            </li>
                            <li class="menuLink" id="dropdownListItem">
                                <button id="dropdownToggleButton" aria-live="assertive" aria-expanded="false" tabindex="0">
                                    games
                                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="12 12 24 24"><path fill="white" d="m24 30.75-12-12 2.15-2.15L24  26.5l9.85-9.85L36 18.8Z" /></svg>
                                </button>

                                <ul id="gamesSubmenu" aria-hidden="true">
                                    <li id="listItemSolitaire">
                                        <a href="/default.htm#solitaire" tabindex="0">Solitaire Collection</a>
                                    </li>
                                    <li id="listItemMahjong">
                                        <a href="/default.htm#mahjong" tabindex="0">Mahjong</a>
                                    </li>
                                    <li id="listItemJigsaw">
                                        <a href="/default.htm#jigsaw" tabindex="0">Jigsaw</a>
                                    </li>
                                    <li id="listItemMinesweeper">
                                        <a href="/default.htm#minesweeper" tabindex="0">Minesweeper</a>
                                    </li>
                                    <li id="listItemSudoku">
                                        <a href="/default.htm#sudoku" tabindex="0">Sudoku</a>
                                    </li>
                                    <li id="listItemUltimateWord">
                                        <a href="/default.htm#ultimateword" tabindex="0">Ultimate Word Games</a>
                                    </li>
                                    <li id="listItemTreasureHunt">
                                        <a href="/default.htm#treasurehunt" tabindex="0">Treasure Hunt</a>
                                    </li>
                                    <li id="listItemWordament">
                                        <a href="/default.htm#wordament" tabindex="0">Wordament</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="menuLink">
                                <a href="/default.htm#about">about</a>
                            </li>
                            <li class="menuLink">
                                <a href="https://aka.ms/MCGShop" target="_blank">shop</a>
                            </li>
                        </ul>
                    </div>
                </header>
            `
        }
    }

    class Footer extends HTMLElement {
        connectedCallback() {
            this.innerHTML = `
                <footer>
                    <div class="footerLeft">
                        <div class="microsoftLogo">
                            <img alt="Microsoft" src="/assets/images/UI/msft_logo.png" />
                            <span class="microsoftText">Microsoft</span>
                        </div>
                        <span class="microsoftCopyright">&copy; 2024 Microsoft</span>
                    </div>

                    <div class="footerCenter">
                        <a href="https://aka.ms/MCG.comContactUs" target="_blank" title="Contact Us">Contact Us</a>
                        <a href="https://windows.microsoft.com/en-us/windows/microsoft-services-agreement" target="_blank" title="Terms of Use">Terms of Use</a>
                        <a href="/WebContent/oss-notice/open-source-software-notice.html" target="_blank" title="Third Party Software Notice">Third Party Software Notice</a>
                        <a href="https://www.microsoft.com/trademarks/" target="_blank" title="Trademarks">Trademarks</a>
                        <a href="https://aka.ms/MCG.comSupport" target="_blank" title="Support">Support</a>
                        <br>
                        <a href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" title="Privacy & Cookies">Privacy & Cookies</a>
                        <a href="https://go.microsoft.com/fwlink/?linkid=2259814" target="_blank" title="Consumer Health Privacy">Consumer Health Privacy</a>
                        <a class="privacyPolicyText hiddenLink" href="https://aka.ms/YourCaliforniaPrivacyChoices" target="_blank"  id='yourPrivacyChoices' title="Your Privacy Choices">
                            Your Privacy Choices
                            <img src="/assets/images/UI/privacyoptions29x14.png" alt="Privacy choices logo" />
                        </a>
                        <a href="#" class="manageCookies hiddenLink" id="manageCookies" title="Manage Cookies">Manage Cookies</a>
                    </div>

                    <div class="footerRight">
                        <a href="https://aka.ms/MCG_Home_Twitter" title="Visit us on Twitter" target="_blank">
                            <img src="/assets/images/SocialMedia/twitterIcon.svg" alt="Twitter Icon" />
                        </a>
                        <a href="https://aka.ms/MCG_Home_Facebook" title="Visit us on Facebook" target="_blank" class="fbButton">
                            <img src="/assets/images/SocialMedia/facebookIcon.svg" alt="Facebook Icon" />
                        </a>
                        <a href="https://aka.ms/MCG_Home_YouTube" title="Visit us on Youtube" target="_blank">
                            <img src="/assets/images/SocialMedia/youtubeIcon.svg" alt="Youtube Icon" />
                        </a>
                    </div>
                </footer>
            `
        }
    }

    class NewsPageSparseHeader extends HTMLElement {
        get gameTitle() { return this.getAttribute('gameTitle'); }

        connectedCallback() {
            this.innerHTML = `
                <header>
                    <a class="blueButton normalButton returnToGameButton" tabindex="0">
                        <span class="backArrow">&#10132;</span> Back to ${this.gameTitle}
                    </a>

                    <a class='headerLogo' href="/default.htm">
                        <div class="text">Microsoft Casual Games</div>
                        <img src="/assets/images/UI/logoCards.png" alt="Cards Logo" />
                    </a>
                </header>
            `
        }
    }

    // Register the components
    customElements.define('mcg-header', Header);
    customElements.define('mcg-footer', Footer);
    customElements.define('mcg-sparse-header', NewsPageSparseHeader);
}