﻿var siteConsent = null;

function loadCookieConsentBanner() {
    var bannerElement = document.createElement('div');
    bannerElement.id = 'cookieConsentBanner';
    document.body.insertBefore(bannerElement, document.body.firstChild);

    if (window.WcpConsent) {
        WcpConsent.init(
            'en-US',
            'cookieConsentBanner',
            onInit,
            onConsentChanged
        );
    }
}

function loadCookieConsentText() {
    $('.firstBlock').text('The video content on this page is currently blocked by your permission settings.');
    $('.secondBlock').text('To view this video and improve your social and advertising experience, click “Manage Cookies” at the bottom of the page.');
}

function logConsent(name, props) {
    if (typeof appInsights !== 'undefined') {
        appInsights.trackEvent({ name: name }, props);
    }
}

function manageCookies(event) {
    event.preventDefault();
    siteConsent.manageConsent();
}

function onConsentChanged(userConsent) {
    setWcpConsent(
        userConsent.Required,
        userConsent.Analytics,
        userConsent.SocialMedia,
        userConsent.Advertising
    );
}

function onInit(err, _siteConsent) {
    if (!err) {
        siteConsent = _siteConsent;
        var userConsent = siteConsent.getConsent();
        setWcpConsent(
            userConsent.Required,
            userConsent.Analytics,
            userConsent.SocialMedia,
            userConsent.Advertising
        );

        function setFooterLink(linkElement) {
            linkElement.addEventListener('click', manageCookies);
            linkElement.classList.remove('hiddenLink');
        }

        if (siteConsent.isConsentRequired) {
            var manageCookiesLink = document.querySelector('#manageCookies');
            if (manageCookiesLink instanceof HTMLElement) {
                setFooterLink(manageCookiesLink);
            } else {
                window.addEventListener('message', function (e) {
                    if (e.data.name && e.data.name === 'buildFooterComplete') {
                        setFooterLink(document.querySelector('#manageCookies'));
                    }
                });
            }
        }
    } else {
        logConsent('wcpConsentError', err);
    }
}

function setWcpConsent(required, analytics, socialmedia, advertising) {
    var consent = {
        required: required,
        analytics: analytics,
        socialMedia: socialmedia,
        advertising: advertising
    };
    window.postMessage(
        {
            name: 'setWcpConsent',
            userConsent: consent
        },
        '*'
    );
    logConsent('setWcpConsent', consent);

    if (required && socialmedia && advertising)
        changeYoutubeSource(true);
    else
        changeYoutubeSource(false);
}

function changeYoutubeSource(showVideo) {
    if (showVideo) {
        $(".embedded-yt").each(function () {
            this.src = $(this).attr("data-src");
            $(this).css("background-image", "url(\"\")");
        });

        $(".yt-link-container").each(function () {
            $(this).css("visibility", "hidden");
        });

        $(".cookie-consent-container").each(function () {
            $(this).css("visibility", "hidden");
        });
    }
    else {
        $(".embedded-yt").each(function () {
            this.src = "";
            $(this).css("background-image", "url(/assets/images/Backgrounds/solitaire_bg_very_dark.jpg");
        });

        $(".yt-link-container").each(function () {
            $(this).css("visibility", "visible");
        });

        $(".cookie-consent-container").each(function () {
            $(this).css("visibility", "visible");
        });
    }
}

if (document.readyState === 'loading') {  // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', function (event) {
        loadCookieConsentBanner();
        loadCookieConsentText();
    });
} else {  // `DOMContentLoaded` has already fired
    loadCookieConsentBanner();
}
