$(function () {
    // Firefox enables videos to be played/paused by clicking anywhere within the video element.
    // Adding the onclick attribute to the video element enables that feature for the
    // other browsers, but completely breaks the play/pause functionality in Firefox.
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        $("video").removeAttr("onclick");
    }

    var dropdownSpeedMilliseconds = 150;
    var dropdownCooldownActive = false;
    var dropdownOnMouseLeaveCallback;
    var isdropdownOpen = false;
    var focusoutCooldownActive = false;
    const dropdownLinkArray = document.querySelectorAll('#gamesSubmenu a');
    let currentDropdownFocusIndex = -1;

    function resetDropdownCooldown() {
        // Used to let the animations to finish before a new jquery animation can fire.
        if (!dropdownCooldownActive) {
            dropdownCooldownActive = true;
            setTimeout(() => { dropdownCooldownActive = false }, dropdownSpeedMilliseconds);
        }
    }

    $("#dropdownListItem").mouseenter(function () {
        resetDropdownCooldown();
        if (dropdownOnMouseLeaveCallback) $("#dropdownToggleButton").off("mouseleave");
        openSubmenuForced();
        dropdownOnMouseLeaveCallback = $("#dropdownListItem").mouseleave(function (event) {
            $("#dropdownToggleButton").off("mouseleave");
            if (!event.currentTarget.contains(event.relatedTarget)) {
                closeSubmenu();
            }
        })
    });

    window.addEventListener("keydown", function(e) {
        resetDropdownCooldown();

        // Close submenu
        if (e.key === 'Escape') { closeSubmenu(); }

        // Navigate submenu with up/down keys
        if (e.key === 'ArrowUp' && isdropdownOpen == true) {
            e.preventDefault();
            currentDropdownFocusIndex -= 1;
            if (currentDropdownFocusIndex < 0) {
                currentDropdownFocusIndex = 7;
            }
            dropdownLinkArray[currentDropdownFocusIndex].focus();
        }
        if (e.key === 'ArrowDown' && isdropdownOpen == true) {
            e.preventDefault();
            currentDropdownFocusIndex += 1;
            if (currentDropdownFocusIndex > 7) {
                currentDropdownFocusIndex = 0;
            }
            dropdownLinkArray[currentDropdownFocusIndex].focus();
        }

        // Click submenu items with the spacebar
        // The enter key also works automatically
        if (e.key === ' ' && isdropdownOpen == true) {
            e.preventDefault();
            dropdownLinkArray[currentDropdownFocusIndex]?.click();
        }
    });

    $("#dropdownListItem").focusout(function (event) {
        resetDropdownCooldown();
        if (!focusoutCooldownActive && !event.currentTarget.contains(event.relatedTarget)) { closeSubmenu(); }
    });

    $("#gamesSubmenu").find("li").click(function () {
        resetDropdownCooldown();
        closeSubmenu();
    });

    // Using .click(), while redundant on a PC, enables the dropdown menu to appear when using touch screen devices.
    // The ternary operator prevents any conflict with existing .mouseenter() and .mouseleave() functions.
    $("#dropdownToggleButton").click(function (clickEvent) {
        resetDropdownCooldown();
        toggleSubmenu();
        refocusElement(clickEvent.currentTarget);
    });

    // Opening the Games menu with the keyboard
    $("#dropdownToggleButton").keydown(function (keyDownEvent) {
        if (!dropdownCooldownActive &&
        (keyDownEvent.key === 'Enter' || keyDownEvent.key === ' ')) {
            keyDownEvent.preventDefault();
            resetDropdownCooldown();
            toggleSubmenu();
            refocusElement(keyDownEvent.currentTarget);
        }
    });

    function refocusElement(element) {
        focusoutCooldownActive = true;
        element.blur();
        setTimeout(() => {
            element.focus();
            focusoutCooldownActive = false;
        }, dropdownSpeedMilliseconds);
    }

    function openSubmenu() {
        isdropdownOpen = true;
        $("#gamesSubmenu").slideDown(dropdownSpeedMilliseconds);
        updateDropdownAriaAttributes(isdropdownOpen);
    }

    function closeSubmenu() {
        isdropdownOpen = false;
        currentDropdownFocusIndex = -1;
        $("#gamesSubmenu").slideUp(dropdownSpeedMilliseconds);
        updateDropdownAriaAttributes(isdropdownOpen);
    }

    function openSubmenuForced() {
        isdropdownOpen = true;
        $("#gamesSubmenu").stop(true, true).slideDown(dropdownSpeedMilliseconds);
        updateDropdownAriaAttributes(isdropdownOpen);
    }

    function toggleSubmenu() {
        isdropdownOpen = !isdropdownOpen;
        $("#gamesSubmenu")["slide" + (isdropdownOpen ? "Down" : "Up")](dropdownSpeedMilliseconds);
        updateDropdownAriaAttributes(isdropdownOpen);
    }

    function updateDropdownAriaAttributes(isSubmenuOpen) {
        const buttonElement = $("#dropdownToggleButton")[0];
        const submenu = $("#gamesSubmenu")[0];
        // This does not work for some reason so I'm passing the value in from the previous scope where it does work.
        // const isSubmenuVisible = $("#gamesSubmenu").is(":visible");
        if (!submenu || !buttonElement) { return; }

        buttonElement.setAttribute('aria-expanded', (isSubmenuOpen ? "true" : "false"));
        submenu.setAttribute('aria-hidden', (isSubmenuOpen ? "false" : "true"));
    }
});

function getKeyboardFocusableElements(element) {
    return Array.from(element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    )).filter(
        (foundElement) => !foundElement.hasAttribute('disabled') && !foundElement.getAttribute('aria-hidden'),
    );
}

function scrollToElement(query) {
    const element = document.querySelector(query);
    if (element) {
        element.scrollIntoView({ top: 0 });
        setTimeout(() => {
            const focusableElements = getKeyboardFocusableElements(element);
            if (focusableElements.length > 0) (focusableElements[0]).focus();
        }, 0);
    } else {
        console.warn(`Element does not exist with query: ${query}`);
    }
}