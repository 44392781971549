﻿$(function () {
    // This function is called at document.ready in order to properly process a page refresh.
    $(document).ready(function() {
        processURLHash();
    });

    // Hide all elements not intended to be visible in the home view.
    $("#viewContainer").children().children().hide();
    $(".otherLabel").hide();
    $("#facebookTiles").hide();

    // Click a thumbnail to load the full image in place of the video element
    var thumbs = $("img[data-full]");
    thumbs.on("click", function () {
        selectImageThumbnail($(this));
    });
    thumbs.keyup(function (keyUpEvent) {
        if (keyUpEvent.key === 'Enter' || keyUpEvent.keyCode === 13) {
            selectImageThumbnail($(this));
        }
    });

    // Click the video thumbnail to play the video, or replace the video element and play. If video is playing, does nothing.
    $(".videoThumb").on("click", selectVideoThumbnail);
    $(".videoThumb").keyup(function (keyUpEvent) {
        if (keyUpEvent.key === 'Enter' || keyUpEvent.keyCode === 13) {
            selectVideoThumbnail();
        }
    });
});

$(window).on("hashchange", processURLHash);

function selectImageThumbnail(element) {
    // Click a thumbnail to load the full image in place of the video element
    var video = $("#viewContainer > * > :visible .video-container");
    var thisImageViewer = $("#viewContainer > * > :visible .videoImagePane");
    var thisThumbRow = $("#viewContainer > * > :visible .gameScreenshots");
    var marginTopH = 12;
    var imageW = thisImageViewer.children(":visible").width();
    var full = thisImageViewer.find(".full").eq(element.data("full"));
    thisImageViewer.children().css("position", "absolute");
    thisImageViewer.children().width(imageW);
    thisThumbRow.css("margin-top", marginTopH);
    $("video").each(function () {
        this.pause();
    });
    thisImageViewer.children(":visible").fadeOut(0);
    full.fadeIn(0, undoImageAbsoluteP)
}

function selectVideoThumbnail() {
    // Click the video thumbnail to play the video, or replace the video element and play. If video is playing, does nothing.
    var video = $("#viewContainer > * > :visible .video-container");
    var thisImageViewer = $("#viewContainer > * > :visible .videoImagePane");
    var thisThumbRow = $(this).closest(".gameScreenshots");
    var marginTopH = thisImageViewer.children(":visible").height() + 12;
    var imageW = thisImageViewer.children(":visible").width();
    var full = thisImageViewer.find(".full").eq($(this).data("full"));
    thisImageViewer.children().css("position", "absolute");
    thisImageViewer.children().width(imageW);
    thisThumbRow.css("margin-top", marginTopH);
    video.is(":visible") ? video.trigger("play") :
        thisImageViewer.children(":visible").fadeOut(0);
    video.fadeIn(0, undoImageAbsoluteP);
    video.trigger("play");
}

function despecifyW() {
    this.parentElement.style.width = "";
}

function undoFadeStyles() {
    $("#viewContainer").children().css({
        "position": "relative",
        "max-width": ""
    });
    $("#gameTiles").css("margin-top", "");
    var thisImageViewer = $("#viewContainer").find($(".videoImagePane"));
    thisImageViewer.find("video").css("margin-bottom", 0);
    $("#viewContainer").children().css("width", "100%");
}

function fadeToView(fromView, toView) {
    if (fromView.length == 0) {
        toView.children().fadeIn(200, despecifyW);
    }
    else {
        $("video").each(function () {
            this.pause();
        });
        var actualW = fromView.width();
        fromView.width(actualW);
        toView.width(actualW);
        var marginH = $("#viewContainer").height();
        $("#viewContainer").children().css({
            "position": "absolute",
        });
        $("#gameTiles").css("margin-top", marginH);
        $("#blocks").hide();
        var viewH = $("#viewContainer").children(":visible").height();
        fromView.children().fadeOut(600);
        toView.children().fadeIn(600, undoFadeStyles);
    }
}

function slideToView(fromView, toView) {
    var actualW = fromView.width();
    fromView.width(actualW);
    toView.width(actualW);
    fromView.children().slideUp();
    toView.children().slideDown(despecifyW);
    $("video").each(function () {
        this.pause();
    });
}

function undoImageAbsoluteP() {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var thisImageViewer = $("#viewContainer").find($(".videoImagePane"));
    var thisScreenshotsRow = $("#viewContainer").find($(".gameScreenshots"));
    thisImageViewer.children().css({
        "position": "",
        "width": ""
    });
    isIE ? thisImageViewer.find("video").css("margin-bottom", 0) : thisImageViewer.find("video").css("margin-bottom", 5);
    thisScreenshotsRow.css("margin-top", 0);
}

function pauseAllYoutubeVideos() {
    $('.embedded-yt').each(function(){
        this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
    });
}

function processURLHash() {
    pauseAllYoutubeVideos();

    // TODO: If an unexpected #Hash shows up this function errors out.
    // If you know jquery well enough, please fix.
    const views = $("#viewContainer").children();
    const homeView = $("#homeView");
    const aboutView = $("#aboutView");
    const gameView = $(".gameView");
    const fromView = views.children(":visible").parent();
    let toView = views.filter(location.hash + "View");

    if (toView.length == 0) { // location.hash doesn't match any view element ids
        toView = homeView;
        slideToView(fromView, toView);
    }
    // fromView.length is 10 when a page first loads
    else if (fromView.is(gameView) && toView.is(gameView) && fromView.length == 1) {
        fadeToView(fromView, toView);
    }
    else {
        slideToView(fromView, toView);
    }

    $("#blocks")["slide" + (toView.is(homeView) ? "Down" : "Up")]();
    $(".otherLabel")["slide" + (toView.is(gameView) ? "Down" : "Up")]();
    $("#gameTiles")["slide" + (!toView.is(aboutView) ? "Down" : "Up")]();
    // Deactivated on March 2023, pending redesign
    // $("#facebookTiles")["slide" + (!toView.is(aboutView) && !toView.is(homeView) ? "Down" : "Up")]();

    const titleLocation = location.hash.substring(1).charAt(0).toUpperCase() + location.hash.substring(1).slice(1);
    let newTitle = 'Microsoft Casual Games'
    titleLocation ? (newTitle += ` - ${titleLocation}`) : newTitle = 'Microsoft Casual Games - Home' // Default to home
    document.title = newTitle;
}