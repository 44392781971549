const contentSecurityPolicy = {
    "http-equiv": 'Content-Security-Policy',
    value: `
        default-src 'self';
        frame-src 'self'
            https://www.youtube.com;
        script-src 'self'
            https://www.microsoft.com
            https://wcpstatic.microsoft.com

            https://www.youtube.com
            https://www.youtube-nocookie.com

            https://az416426.vo.msecnd.net
        style-src 'self' 'unsafe-inline'
            https://www.microsoft.com;
        img-src 'self'
        connect-src 'self'
        font-src 'self';
        object-src 'none';
        frame-ancestors 'self';
        base-uri 'self';
`}

function updateMetadata(metaData) {
    // Create a new meta element with the metadata
    const newMetaElement = document.createElement('meta');
    const objectEntries = Object.entries(metaData);
    for (const [key, value] of objectEntries) {
        newMetaElement.setAttribute(key, value || 'undefined');
    }

    // If the metadata already exists, replace it. Otherwise, add it.
    let existingMetaElement;
    if (objectEntries && objectEntries.length > 0) {
        existingMetaElement = document.head.querySelector(
            `meta[${objectEntries[0][0]}=${objectEntries[0][1]}]`
        );
    }
    if (existingMetaElement) {
        document.head.replaceChild(newMetaElement, existingMetaElement);
    } else {
        document.head.appendChild(newMetaElement);
    }
}

updateMetadata(contentSecurityPolicy);
