// Automatically update the copyright icon in the footer
function setCopyrightYear() {
    const date = new Date();
    const year = date.getFullYear();
    const textArea = document.querySelector(".microsoftCopyright");
    if (textArea) {
        textArea.innerHTML = "&copy " + year + " Microsoft";
    }
}

// Get user location data
// Example response: { country: 'US', gdpr: false, pipl: false, lgpd: false }
// let savedLocationData = null;
export function getUserLocationData() {
    return new Promise((resolve, reject) => {
        // @todo: revisit this and see if we want to cache the response in localstorage. Caching to a var doesn't work because this isn't a single page app
        // If we already loaded this data, return it immediately without making a new API call
        // if (savedLocationData) {
        //     resolve(savedLocationData);
        //     return;
        // }

        // If this is the first time, make the API call
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://idmapper.microsoftcasualgames.com/api/v2/privacyclassification', true);
        xhr.setRequestHeader('x-functions-key', 'U18xQzpAY0trQm85PjlSWzp5OUBSTyVJKyZUNkw3QSk=');
        xhr.timeout = 10000;

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 400) {
                try {
                    response = JSON.parse(xhr.responseText);
                } catch (error) {
                    reject(new Error('getUserLocationData() Invalid JSON response:'));
                }

                // savedLocationData = response; // Cache the response
                resolve(response);
            } else {
                reject(new Error(`getUserLocationData() XMLHttpRequest request failed with status ${xhr.status}`));
            }
        };

        xhr.onerror = () => {
            reject(new Error(`getUserLocationData() XMLHttpRequest request failed with status ${xhr.status}`));
        };
        xhr.ontimeout = () => {
            reject(new Error(`getUserLocationData() XMLHttpRequest request timeout with status ${xhr.status}`));
        };
        xhr.send();
    });
}

// Update some things after the web components initialize
window.addEventListener("DOMContentLoaded", (event) => {
    setCopyrightYear();

    if (!window.location.href.includes('/news/')) {
        getUserLocationData()
            .then((locationData) => {
                if (locationData.country?.toUpperCase?.() === 'US') {
                    document.getElementById('yourPrivacyChoices').classList.remove('hiddenLink');
                }
            })
            .catch((errorData) => {
                // Show footer link by default
                document.getElementById('yourPrivacyChoices').classList.remove('hiddenLink');
                console.error(errorData);
            });
    }
});

// Force web component images to load asap, otherwise they'll be loaded dead last
// To further reduce UI jitter, preset CSS height/widths for these images whenever possible
function preloadImage(url) {
    var img = new Image();
    img.src = url;
}
preloadImage('/assets/images/UI/logoCards.png');
preloadImage('/assets/images/UI/msft_logo.png');
preloadImage('/assets/images/UI/privacyoptions29x14.png');
preloadImage('/assets/images/SocialMedia/twitterIcon.svg');
preloadImage('/assets/images/SocialMedia/facebookIcon.svg');
preloadImage('/assets/images/SocialMedia/youtubeIcon.svg');

// Fixes dropdown menu backgrounds not loading until the menu is opened
preloadImage('/assets/images/GamesMenuItems/solitaireListItem.png');
preloadImage('/assets/images/GamesMenuItems/mahjongListItem.png');
preloadImage('/assets/images/GamesMenuItems/jigsawListItem.png');
preloadImage('/assets/images/GamesMenuItems/minesweeperListItem.png');
preloadImage('/assets/images/GamesMenuItems/sudokuListItem.png');
preloadImage('/assets/images/GamesMenuItems/treasurehuntListItem.png');
preloadImage('/assets/images/GamesMenuItems/uwgListItem.png');
preloadImage('/assets/images/GamesMenuItems/wordamentListItem.png');
